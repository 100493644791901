import {selectOwner as selectOwnerPure} from '../pureActions/productionUnitAddActions';
import { createAsyncAction } from '../../utils/asyncHelpers';
import { hydrogenFuelDataFetch } from '../actionHelpers/endpoints';
import { HYDROGEN_FUEL_DATA_FETCH } from '../../constants/actionTypes/hydrogenActionTypes';

export const selectOwner = id => dispatch => dispatch(selectOwnerPure(id));

export const fetchHydrogenFuelDataForProductionPeriod = createAsyncAction(
  HYDROGEN_FUEL_DATA_FETCH,
  hydrogenFuelDataFetch
);
