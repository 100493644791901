import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PropTypesImmutable from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { Steps } from 'antd';
import { translate } from 'react-i18next';
import {
  goPrevActionCreator,
  goNextActionCreator,
  resetListCertificatesCreator,
} from '../../actions/actionCreators/addEquipmentActionCreators';
import {
  AddEquipmentGeneral,
  AddEquipmentTechnicalInformation,
  AddEquipmentFuelsUsed,
  AddEquipmentActivityPeriod,
  AddEquipmentDocuments,
} from '../../forms';
import * as addEquipmentSelectors from '../../reducers/addEquipmentReducer';
import { getProductionUnit } from '../../schemas/productionUnit';
import { getProductionUnitsEditState } from '../../reducers/legalEntityProductionUnitsReducer';
import RegisterMeasurement from '../RegisterMeasurement/RegisterMeasurement';
import { isHydrogen } from '../../utils/productionUnitUtil';

const steps = [
  AddEquipmentGeneral,
  AddEquipmentTechnicalInformation,
  AddEquipmentFuelsUsed,
  AddEquipmentFuelsUsed,
  AddEquipmentActivityPeriod,
  RegisterMeasurement,
  AddEquipmentDocuments,
];

const hydrogenSteps = [
  AddEquipmentGeneral,
  AddEquipmentTechnicalInformation,
  AddEquipmentFuelsUsed,
  AddEquipmentDocuments,
];

class AddEquipment extends Component {
  static defaultProps = {
    isProductionUnitAdd: false,
    selectProductionUnitOwner: false,
  };
  static propTypes = {
    t: PropTypes.func.isRequired,
    currentStep: PropTypes.number.isRequired,
    goPrevious: PropTypes.func.isRequired,
    resetListCertificates: PropTypes.func.isRequired,
    goNext: PropTypes.func.isRequired,
    hasDone: PropTypes.bool.isRequired,
    hasNext: PropTypes.bool.isRequired,
    hasPrevious: PropTypes.bool.isRequired,
    onSubmitSuccess: PropTypes.func,
    productionUnit: PropTypes.object,
    legalEntity: PropTypesImmutable.map,
    legalEntityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isAdmin: PropTypes.bool.isRequired,
    isEditMode: PropTypes.bool.isRequired,
    isProductionUnitAdd: PropTypes.bool,
    selectProductionUnitOwner: PropTypes.bool,
    fuelType: PropTypes.string,
    isHydrogenContext: PropTypes.bool,
  };

  componentDidMount() {
    const { isProductionUnitAdd, resetListCertificates } = this.props;

    if (isProductionUnitAdd) {
      resetListCertificates();
    }
  }

  render() {
    const {
      t,
      currentStep,
      goPrevious,
      goNext,
      hasPrevious,
      onSubmitSuccess,
      productionUnit,
      legalEntityId,
      isAdmin,
      isEditMode,
      isProductionUnitAdd,
      selectProductionUnitOwner,
      fuelType,
      isHydrogenContext,
    } = this.props;

    const isHydro = isHydrogen(fuelType) || isHydrogenContext;
    const StepComponent = isHydro
      ? hydrogenSteps[currentStep]
      : steps[currentStep];

    const isNext = isHydro
      ? currentStep < hydrogenSteps.length - 1
      : currentStep < steps.length - 1;

    return (
      <div className="master-add-equipment">
        <Steps size="big" current={currentStep}>
          <Steps.Step title={t('title_general')} />
          <Steps.Step title={t('title_technical_info')} />
          <Steps.Step
            title={
              isHydro
                ? t('title_hydro_fuels_proportion')
                : t('title_fuels_proportion')
            }
          />
          {!isHydro && <Steps.Step title={t('title_activity_period')} />}
          {!isHydro && <Steps.Step title={t('title_measurement')} />}
          <Steps.Step title={t('title_documents')} />
        </Steps>
        <div className="page-register__content">
          <StepComponent
            hasPrevious={hasPrevious}
            goPrevious={goPrevious}
            onSubmitSuccess={
              isNext ? (currentStep === 0 ? null : goNext) : onSubmitSuccess
            }
            goNext={goNext}
            hasDone={!isNext}
            productionUnit={productionUnit}
            legalEntityId={legalEntityId}
            t={t}
            isEditMode={isEditMode}
            isAdmin={isAdmin}
            inEditForNotAdmin={isEditMode && !isAdmin}
            isProductionUnitAdd={isProductionUnitAdd}
            selectProductionUnitOwner={selectProductionUnitOwner}
            fuelType={fuelType}
            isHydrogenContext={isHydro}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentStep: addEquipmentSelectors.getCurrentStep(state),
  hasDone: addEquipmentSelectors.hasDone(state),
  hasNext: addEquipmentSelectors.hasNext(state),
  hasPrevious: addEquipmentSelectors.hasPrevious(state),
  productionUnit: getProductionUnit(state, getProductionUnitsEditState(state)),
});

const mapDispatchToProps = {
  goNext: goNextActionCreator,
  goPrevious: goPrevActionCreator,
  resetListCertificates: resetListCertificatesCreator,
};

export default translate('addEquipment')(
  connect(mapStateToProps, mapDispatchToProps)(AddEquipment)
);
